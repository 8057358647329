import React from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

export const GA_TRACKING_ID = String(process.env.NEXT_PUBLIC_GOOGLE_TAG_ID);

export const pageviewGA = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

export const eventGA = (action: string, params?: Gtag.EventParams | Gtag.ControlParams | Gtag.CustomParams) => {
  window.gtag('event', action, params);
};

export const GoogleAnalyticsScript = () => {
  const router = useRouter();

  React.useEffect(() => {
    router.events.on('routeChangeComplete', pageviewGA);
    router.events.on('hashChangeComplete', pageviewGA);
    return () => {
      router.events.off('routeChangeComplete', pageviewGA);
      router.events.off('hashChangeComplete', pageviewGA);
    };
  }, [router.events]);

  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};
